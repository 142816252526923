/* Reset Styles */

.p-none {
	padding: 0;
}

body {
	font-family: 'Montserrat', sans-serif;
}

.container-fluid {
	width: 100%;
	max-width: 100%;
}

.img-fluid {
	width: 100%;
	height: auto;
}

.wrapper--input {
	margin: 0 0 12px 0px;
}

.w-100 {
	width: 100%;
}

.h-100 {
	height: 100%;
}

.pt-1 {
	padding-top: 1em;
}

.img-cover {
	height: 100vh;
	padding: 0;
}

.img-cover img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.c-white {
	color: #fff;
}

.c-gray {
	color: #ddd;
}

.wrapper-page-header--antd {
	position: initial;
}

.wrapper-calendar .ant-fullcalendar-header .ant-radio-group {
	display: none;
}

.menuBar {
	padding: 8px 20px;
	border-bottom: solid 1px #e8e8e8;
	overflow: auto;
	box-shadow: 0 0 30px #f3f1f1;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.wrapper-item-document {
	width: 100%;
	max-width: -webkit-fill-available;
	background: rgb(250, 250, 250);
	height: auto;
	cursor: pointer;
	border-radius: 16px;
	margin: 1em;
	border: 2px dashed rgb(221, 221, 221);
	/* padding: 1em; */
	/* display: flex; */
	/* justify-content: center; */
	/* align-items: center;*/
}

.wrapper-item-document:focus {
	outline: transparent auto 0px !important;
}

.wrapper-table--pdf * {
	font-size: 8px !important;
}

.btn-primary {
	background: rgb(0, 110, 255);
	color: white;
	padding: 1em 4em;
}

.wrapper-container--users {
	min-height: 80%;
	min-width: 280px;
	border-radius: 1em;
	padding: 1em;
	background: white;
	box-shadow: 0 0px 10px -60px rgba(0, 0, 0, 0.2), 0 4px 15px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0);
	position: absolute;
	overflow: hidden;
	z-index: 99;
}

.scrollbar {
	overflow-x: scroll;
}

.style-red::-webkit-scrollbar {
	width: 12px;
	height: 8px;
	background-color: #f5f5f5;
}

.style-red::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #d62929;
	background-image: -webkit-linear-gradient(90deg, #ff6a00, #ee0979);
}

/* .ant-avatar > img {
	object-position: left !important;
} */

.wrapper-firm-user {
	width: 100% !important;
	height: auto !important;
	line-height: 260px !important;
	font-size: 18px !important;
}
.wrapper-firm-user img {
	/* padding: 1em !important;
	transform: scale(2) !important;
	object-fit: contain !important; */
}

@media (min-width: 1281px) {
	.wrapper-container--users {
		bottom: 104px;
		right: 1em;
	}
}

@media (max-width: 1024px) {
	.wrapper-container--users {
		height: 320px;
		min-height: auto;
		width: 100%;
		max-width: 90%;
		position: absolute;
		bottom: 32px;
		right: 2em;
	}
}
@media (min-width: 768px) {
	.btn-show-mobile {
		display: none !important;
	}
}

@media (max-width: 768px) {
	.wrapper-container--users {
		bottom: 16px;
		right: 2em;
	}

	.title--h1-mobile {
		font-size: 2em !important;
	}

	.btn-hidden-mobile {
		display: none !important;
	}
}
