@media (min-width: 1281px) {
}

/*--------------------------------------------------------------
    laptop, desktop
---------------------------------------------------------------*/

@media only screen and (min-width: 1280px) and (max-width: 1919px) {
}

@media only screen and (min-width: 1201px) and (max-width: 1279px) {
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
}

/*--------------------------------------------------------------
    tablet
---------------------------------------------------------------*/

/* @media only screen and (min-width: 991px) and (max-width: 1024px) {
	
} */

@media only screen and (min-width: 768px) and (max-width: 991px) {
}

/*--------------------------------------------------------------
    large mobile
----------------------------------------------------------------*/

@media only screen and (min-width: 480px) and (max-width: 767px) {
}

/*--------------------------------------------------------------
   medium mobile
----------------------------------------------------------------*/

@media only screen and (min-width: 360px) and (max-width: 479px) {
}

/*--------------------------------------------------------------
    small mobile
----------------------------------------------------------------*/

@media only screen and (min-width: 320px) and (max-width: 359px) {
}

@media (max-width: 999px) {
	.d-block--mobile {
		display: block !important;
	}
}

@media (max-width: 767px) {
	.hidden--mobile {
		display: none;
	}
}
